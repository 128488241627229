#quiz-analyse {
  .digischool-quiz-player {

    .quiz-player {

      #question-container {
        p {
          &.question-text {
            font-size: 18px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.89;
            letter-spacing: normal;
          }
        }
      }

      .subquestion-container {
        .subquestion {
          display: flex;
          margin-bottom: 16px;
          flex-direction: column;

          .subquestion-content {
            margin: auto 0;

            p {
              margin: 0;
              font-size: 18px;
              font-weight: 900;
              line-height: 1.89;
              color: $colorAnalyse;
            }
          }

          .answers-container {
            display: flex;
            flex-direction: column;
            padding: 0;
            list-style: none;

            .answer-container {
              position: relative;
              cursor: pointer;
              padding: 4px;

              &.selected {
                .response {
                  border: solid 2px $answerSelectedContentColor;

                  .title {
                    color: $answerSelectedContentColor;
                  }
                }
              }

              .response {
                width: 100%;
                border-radius: 4px;
                border: solid 1px $answerContentColor;
                box-shadow: 3px 2px 24px 0 rgba(0, 0, 0, 0.09);
                background-color: #ffffff;

                .title {
                  padding: 16px 8px;
                  font-size: 16px;
                  letter-spacing: 0.44px;
                  color: $answerContentColor;
                }
              }

              &.valid {
                .response {
                  border: solid 2px $answerGood;
                  background-color: $answerGood;

                  .title {
                    color: white;
                  }
                }
              }

              &.selected.valid {
                .response {
                  border: solid 2px $answerGood;
                  background-color: transparent;

                  .title {
                    color: $answerGood;
                  }
                }
              }

              &.selected.invalid {
                .response {
                  border: solid 2px $answerBad;
                  background-color: transparent;

                  .title {
                    color: $answerBad;
                  }
                }
              }

              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}