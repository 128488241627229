#quiz-categorisation {
  .digischool-quiz-player {

    .quiz-player {

      #question-container {
        p {
          &.question-text {
            font-size: 16px;
            color: $secondaryTextColor;
            border-bottom: 1px solid #c3c6cc;
            padding-bottom: 24px;
          }
        }
      }

      .subquestion-container {
        .subquestion {
          display: flex;
          margin-bottom: 16px;
          flex-direction: column;

          .subquestion-content {
            margin: auto 0;

            p {
              margin: 0;
              font-size: 18px;
              font-weight: 900;
              line-height: 1.89;
              color: #1b1b1d;
            }
          }

          .answers-container {
            display: flex;
            flex-direction: column;
            padding: 0;
            list-style: none;

            .answer-container {
              position: relative;
              cursor: pointer;
              padding: 4px;

              &.valid, &.invalid {
                .response {
                  border-left: 10px;
                  .title {
                    padding-right: 44px;
                  }
                  &:after {
                    position: absolute;
                    top: 50%;
                    right: 4px;
                    transform: translate(-50%, -50%);
                    width: 20px;
                    height: 20px;
                    content: '';
                    display: block;
                  }
                }
              }

              &.selected {
                .response {
                  border: solid 2px $answerSelectedContentColor;

                  .title {
                    color: $answerSelectedContentColor;
                  }
                }
              }

              .response {
                width: 100%;
                border-radius: 4px;
                border: solid 1px $answerContentColor;
                box-shadow: 3px 2px 24px 0 rgba(0, 0, 0, 0.09);
                background-color: #ffffff;

                .title {
                  padding: 16px 8px;
                  font-size: 16px;
                  letter-spacing: 0.44px;
                  color: $answerContentColor;
                }
              }

              &.valid {
                .response {
                  border: solid 2px $answerGood;
                  background-color: $answerGood;

                  .title {
                    color: $secondaryTextColor;
                  }
                  &:after {
                    background: url("./assets/ic-good-answer.svg") no-repeat;
                  }
                }
              }

              &.selected.valid {
                .response {
                  border: solid 2px $answerGood;
                  background-color: white;

                  .title {
                    color: $answerGood;
                  }
                }
              }
  
              &.invalid {
                .response {
                  border: solid 2px $answerBad;
                  background-color: white;

                  &:after {
                    background: url('./assets/ic-bad-answer.svg') no-repeat;
                  }
                }
              }

              &.selected.invalid {
                .response {
                  border: solid 2px $answerBad;
                  background-color: white;

                  .title {
                    color: $secondaryTextColor;
                  }
                }
              }

              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}