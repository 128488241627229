#quiz-quizFinal, #quiz-quizThematique {
  .digischool-quiz-player {

    .quiz-player {

      #question-container {
        p {
          &.question-text {
            font-size: 18px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.89;
            letter-spacing: normal;
          }
        }
      }

      .subquestion-container {
        .subquestion {
          padding: 16px;
          border-radius: 4px;
          box-shadow: 3px 2px 24px 0 rgba(0, 0, 0, 0.09);
          background-color: #ffffff;
          display: flex;
          margin-bottom: 40px;
          justify-content: space-between;

          @media only screen and (max-width: 600px) {
            flex-direction: column;
          }

          .subquestion-content {
            margin: auto 0;

            p {
              margin: 0;
              font-size: 16px;
              color: $mainTextColor;
            }
          }

          .answers-container {
            display: flex;
            list-style: none;

            @media only screen and (max-width: 600px) {
              margin: 0;
              padding: 0;
            }

            .answer {
              @media only screen and (max-width: 600px) {
                width: 50%;
              }
            }

            .answer-container {
              position: relative;
              cursor: pointer;
              padding: 4px;

              &.selected {
                .response {
                  border: solid 2px $answerSelectedContentColor;

                  .title {
                    color: $answerSelectedContentColor;
                  }
                }
              }

              .response {
                width: 100%;
                border-radius: 20px;
                border: solid 1px $answerContentColor;

                .title {
                  padding: 9px 14px;
                  font-size: 14px;
                  font-weight: bold;
                  letter-spacing: 0.44px;
                  text-align: center;
                  color: $answerContentColor;
                }
              }

              &.valid {
                .response {
                  border: solid 2px $answerGood;
                  background-color: $answerGood;

                  .title {
                    color: white;
                  }
                }
              }

              &.selected.valid {
                .response {
                  border: solid 2px $answerGood;
                  background-color: transparent;

                  .title {
                    color: $answerGood;
                  }
                }
              }

              &.selected.invalid {
                .response {
                  border: solid 2px $answerBad;
                  background-color: transparent;

                  .title {
                    color: $answerBad;
                  }
                }
              }

              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}