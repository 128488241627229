@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
html, #root {
  height: 100vh; }

body {
  color: #0a0a0a;
  overflow: hidden;
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: -webkit-filter 1s ease-in-out;
  transition: filter 1s ease-in-out;
  transition: filter 1s ease-in-out, -webkit-filter 1s ease-in-out;
  -webkit-filter: blur(0);
          filter: blur(0); }
  body.blur {
    -webkit-filter: blur(5px);
            filter: blur(5px); }

a {
  color: currentColor; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.alert {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.5;
  border-radius: 4px;
  padding: 15px;
  margin: 15px;
  border: 1px solid transparent;
  border-left: 5px solid transparent; }

.alert-error {
  color: #611a15;
  background: #fdecea;
  border-color: #611a15; }

.ficheInfo img, .video img, .annonce img, .quizThematique img, .quizFinal img, .categorisation img, .analyse img {
  max-width: 100%; }

.ficheInfo .precision, .video .precision, .annonce .precision, .quizThematique .precision, .quizFinal .precision, .categorisation .precision, .analyse .precision {
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px; }

@media only screen and (min-width: 601px) {
  .ficheInfo div:not(.item), .video div:not(.item), .annonce div:not(.item), .quizThematique div:not(.item), .quizFinal div:not(.item), .categorisation div:not(.item), .analyse div:not(.item) {
    padding-left: 24px;
    padding-right: 24px; }
  .item > div {
    padding: 24px; }
  .item > *:not(div) {
    padding-left: 24px;
    padding-right: 24px; } }

@media only screen and (max-width: 600px) {
  .ficheInfo div:not(.item), .video div:not(.item), .annonce div:not(.item), .quizThematique div:not(.item), .quizFinal div:not(.item), .categorisation div:not(.item), .analyse div:not(.item) {
    padding-left: 12px;
    padding-right: 12px; }
  .item > div {
    padding: 12px; }
  .item > *:not(div) {
    padding-left: 12px;
    padding-right: 12px; } }

.item {
  background-color: white;
  color: #18202f;
  line-height: 1.44;
  letter-spacing: 0.3px; }

.ficheInfo > .item > h1 {
  color: #f39200; }

.ficheInfo .precision {
  background: rgba(243, 146, 0, 0.4); }

.video > .item > h1 {
  color: #006e49; }

.video .precision {
  background: rgba(0, 110, 73, 0.4); }

.annonce > .item > h1 {
  color: #000000; }

.annonce .precision {
  background: rgba(0, 0, 0, 0.4); }

.quizThematique > .item > h1 {
  color: #000000; }

.quizThematique .precision {
  background: rgba(0, 0, 0, 0.4); }

.quizFinal > .item > h1 {
  color: #e6007e; }

.quizFinal .precision {
  background: rgba(230, 0, 126, 0.4); }

.categorisation > .item > h1 {
  color: #7fbc4d; }

.categorisation .precision {
  background: rgba(127, 188, 77, 0.4); }

.analyse > .item > h1 {
  color: #82368c; }

.analyse .precision {
  background: rgba(130, 54, 140, 0.4); }

.ficheInfo > .item > .summary {
  border-left: 3px solid #f39200; }
  .ficheInfo > .item > .summary h2 {
    background-color: #f39200; }

.video > .item > .summary {
  border-left: 3px solid #006e49; }
  .video > .item > .summary h2 {
    background-color: #006e49; }

.annonce > .item > .summary {
  border-left: 3px solid #000000; }
  .annonce > .item > .summary h2 {
    background-color: #000000; }

.quizThematique > .item > .summary {
  border-left: 3px solid #000000; }
  .quizThematique > .item > .summary h2 {
    background-color: #000000; }

.quizFinal > .item > .summary {
  border-left: 3px solid #e6007e; }
  .quizFinal > .item > .summary h2 {
    background-color: #e6007e; }

.categorisation > .item > .summary {
  border-left: 3px solid #7fbc4d; }
  .categorisation > .item > .summary h2 {
    background-color: #7fbc4d; }

.analyse > .item > .summary {
  border-left: 3px solid #82368c; }
  .analyse > .item > .summary h2 {
    background-color: #82368c; }

.ficheInfo > .item h2 {
  display: inline-block; }

.summary {
  background-color: white; }
  .summary h2 {
    color: white;
    width: -webkit-max-content;
    width: max-content;
    padding: 0 5px; }
  .summary ul > li {
    font-weight: 600;
    color: #18202f; }

.ficheInfo > .item > .content h3 {
  color: #f39200; }

.video > .item > .content h3 {
  color: #006e49; }

.annonce > .item > .content h3 {
  color: #000000; }

.quizThematique > .item > .content h3 {
  color: #000000; }

.quizFinal > .item > .content h3 {
  color: #e6007e; }

.categorisation > .item > .content h3 {
  color: #7fbc4d; }

.analyse > .item > .content h3 {
  color: #82368c; }

.content {
  color: #18202f;
  background-color: #f1f4f5;
  line-height: 1.44;
  letter-spacing: 0.3px; }

.digischool-quiz-player {
  padding: 5px 10px;
  display: inline-block;
  width: 100%; }
  .digischool-quiz-player .quiz-player {
    display: none; }
    .digischool-quiz-player .quiz-player.active {
      display: block; }
    .digischool-quiz-player .quiz-player .quiz-header {
      z-index: 1;
      position: fixed;
      padding: 0 29px;
      bottom: 10px;
      font-size: 14px;
      line-height: 1.86;
      letter-spacing: 0.3px;
      color: #1b1b1d;
      font-weight: normal;
      margin-top: 0.67em;
      margin-bottom: 0.67em; }
      .digischool-quiz-player .quiz-player .quiz-header .question-counter {
        position: absolute;
        bottom: 10px;
        width: 250px; }
    .digischool-quiz-player .quiz-player #quiz-information {
      display: none; }
    .digischool-quiz-player .quiz-player #question-media-container .medias .media {
      margin-top: 30px; }
    .digischool-quiz-player .quiz-player .medias .media {
      width: 100%;
      height: auto; }
      .digischool-quiz-player .quiz-player .medias .media img {
        width: auto;
        max-width: 100%;
        margin: auto;
        display: block; }
      .digischool-quiz-player .quiz-player .medias .media .loadImg {
        margin: 10px 0;
        transition: opacity 1s ease-in-out;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 1; }
        .digischool-quiz-player .quiz-player .medias .media .loadImg.loaded {
          opacity: 0; }
    .digischool-quiz-player .quiz-player .btn-next,
    .digischool-quiz-player .quiz-player .btn-explanation-quiz {
      outline: none;
      cursor: pointer;
      width: 56px;
      height: 70px;
      border: none;
      font: 0/0 a;
      text-shadow: none;
      color: transparent; }
    .digischool-quiz-player .quiz-player .button-wrapper {
      position: absolute;
      bottom: 95px;
      left: 85%;
      z-index: 100; }
      .digischool-quiz-player .quiz-player .button-wrapper .btn-explanation-wrapper {
        position: absolute;
        right: 70px; }
      .digischool-quiz-player .quiz-player .button-wrapper .btn-next-wrapper {
        position: absolute;
        right: 0; }
      .digischool-quiz-player .quiz-player .button-wrapper .btn-next {
        background: url(/static/media/fab-validate.a9f3d954.svg) no-repeat center; }
        .digischool-quiz-player .quiz-player .button-wrapper .btn-next:disabled {
          opacity: 0.80;
          background: url(/static/media/fab-validate-disabled.80c92e13.svg) no-repeat center; }
        .digischool-quiz-player .quiz-player .button-wrapper .btn-next.arrow {
          background: url(/static/media/fab-next.8f538347.svg) no-repeat center; }
      .digischool-quiz-player .quiz-player .button-wrapper .btn-explanation-quiz {
        background: url(/static/media/fab-explain.82c2b4ef.svg) no-repeat center; }
    .digischool-quiz-player .quiz-player .clear.result {
      position: fixed;
      bottom: 25px;
      z-index: 101;
      left: 50%;
      margin-left: -400px;
      width: 620px;
      text-align: right; }
      .digischool-quiz-player .quiz-player .clear.result h4 {
        display: none; }

.rating {
  height: 70px; }

.ReactModal__Content {
  max-width: 620px;
  margin: auto;
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: auto;
  border: 1px solid #cccccc;
  background: white;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  text-align: center; }
  .ReactModal__Content:not(.quiz-player-explanation) {
    max-width: 90%; }
  .ReactModal__Content h5.modal-explanation-title {
    font-size: 24px;
    text-align: center;
    font-weight: 800;
    color: #79a611; }
  .ReactModal__Content .modal-explanation-btn-close {
    max-width: 150px;
    margin: auto;
    border-radius: 5px;
    width: 100%;
    padding: 12px 0;
    color: white;
    transition: all 0.2s linear;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    background: #77C35E; }

.progress-bar {
  bottom: 0;
  position: absolute;
  width: 250px;
  height: 6px; }
  .progress-bar .progress-bar-background {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    background-color: #ebedee; }
    .progress-bar .progress-bar-background .progress-bar-line {
      height: 100%;
      background-color: #77C35E; }

.quiz-content {
  padding-bottom: 75px; }

#quiz-quizFinal .digischool-quiz-player .quiz-player #question-container p.question-text, #quiz-quizThematique .digischool-quiz-player .quiz-player #question-container p.question-text {
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.89;
  letter-spacing: normal; }

#quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion {
  padding: 16px;
  border-radius: 4px;
  box-shadow: 3px 2px 24px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between; }
  @media only screen and (max-width: 600px) {
    #quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion {
      flex-direction: column; } }
  #quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion .subquestion-content, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion .subquestion-content {
    margin: auto 0; }
    #quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion .subquestion-content p, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion .subquestion-content p {
      margin: 0;
      font-size: 16px;
      color: #1b1b1d; }
  #quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container {
    display: flex;
    list-style: none; }
    @media only screen and (max-width: 600px) {
      #quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container {
        margin: 0;
        padding: 0; } }
    @media only screen and (max-width: 600px) {
      #quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer {
        width: 50%; } }
    #quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container {
      position: relative;
      cursor: pointer;
      padding: 4px; }
      #quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected .response, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected .response {
        border: solid 2px #263c46; }
        #quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected .response .title, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected .response .title {
          color: #263c46; }
      #quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container .response, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container .response {
        width: 100%;
        border-radius: 20px;
        border: solid 1px #9ea2aa; }
        #quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container .response .title, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container .response .title {
          padding: 9px 14px;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.44px;
          text-align: center;
          color: #9ea2aa; }
      #quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid .response, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid .response {
        border: solid 2px #7fbc4d;
        background-color: #7fbc4d; }
        #quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid .response .title, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid .response .title {
          color: white; }
      #quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected.valid .response, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected.valid .response {
        border: solid 2px #7fbc4d;
        background-color: transparent; }
        #quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected.valid .response .title, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected.valid .response .title {
          color: #7fbc4d; }
      #quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected.invalid .response, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected.invalid .response {
        border: solid 2px #ee3547;
        background-color: transparent; }
        #quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected.invalid .response .title, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected.invalid .response .title {
          color: #ee3547; }
      #quiz-quizFinal .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container span, #quiz-quizThematique .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container span {
        display: none; }

#quiz-analyse .digischool-quiz-player .quiz-player #question-container p.question-text {
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.89;
  letter-spacing: normal; }

#quiz-analyse .digischool-quiz-player .quiz-player .subquestion-container .subquestion {
  display: flex;
  margin-bottom: 16px;
  flex-direction: column; }
  #quiz-analyse .digischool-quiz-player .quiz-player .subquestion-container .subquestion .subquestion-content {
    margin: auto 0; }
    #quiz-analyse .digischool-quiz-player .quiz-player .subquestion-container .subquestion .subquestion-content p {
      margin: 0;
      font-size: 18px;
      font-weight: 900;
      line-height: 1.89;
      color: #82368c; }
  #quiz-analyse .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    list-style: none; }
    #quiz-analyse .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container {
      position: relative;
      cursor: pointer;
      padding: 4px; }
      #quiz-analyse .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected .response {
        border: solid 2px #263c46; }
        #quiz-analyse .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected .response .title {
          color: #263c46; }
      #quiz-analyse .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container .response {
        width: 100%;
        border-radius: 4px;
        border: solid 1px #9ea2aa;
        box-shadow: 3px 2px 24px 0 rgba(0, 0, 0, 0.09);
        background-color: #ffffff; }
        #quiz-analyse .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container .response .title {
          padding: 16px 8px;
          font-size: 16px;
          letter-spacing: 0.44px;
          color: #9ea2aa; }
      #quiz-analyse .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid .response {
        border: solid 2px #7fbc4d;
        background-color: #7fbc4d; }
        #quiz-analyse .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid .response .title {
          color: white; }
      #quiz-analyse .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected.valid .response {
        border: solid 2px #7fbc4d;
        background-color: transparent; }
        #quiz-analyse .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected.valid .response .title {
          color: #7fbc4d; }
      #quiz-analyse .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected.invalid .response {
        border: solid 2px #ee3547;
        background-color: transparent; }
        #quiz-analyse .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected.invalid .response .title {
          color: #ee3547; }
      #quiz-analyse .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container span {
        display: none; }

#quiz-categorisation .digischool-quiz-player .quiz-player #question-container p.question-text {
  font-size: 16px;
  color: #18202f;
  border-bottom: 1px solid #c3c6cc;
  padding-bottom: 24px; }

#quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion {
  display: flex;
  margin-bottom: 16px;
  flex-direction: column; }
  #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .subquestion-content {
    margin: auto 0; }
    #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .subquestion-content p {
      margin: 0;
      font-size: 18px;
      font-weight: 900;
      line-height: 1.89;
      color: #1b1b1d; }
  #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    list-style: none; }
    #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container {
      position: relative;
      cursor: pointer;
      padding: 4px; }
      #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid .response, #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.invalid .response {
        border-left: 10px; }
        #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid .response .title, #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.invalid .response .title {
          padding-right: 44px; }
        #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid .response:after, #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.invalid .response:after {
          position: absolute;
          top: 50%;
          right: 4px;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          width: 20px;
          height: 20px;
          content: '';
          display: block; }
      #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected .response {
        border: solid 2px #263c46; }
        #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected .response .title {
          color: #263c46; }
      #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container .response {
        width: 100%;
        border-radius: 4px;
        border: solid 1px #9ea2aa;
        box-shadow: 3px 2px 24px 0 rgba(0, 0, 0, 0.09);
        background-color: #ffffff; }
        #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container .response .title {
          padding: 16px 8px;
          font-size: 16px;
          letter-spacing: 0.44px;
          color: #9ea2aa; }
      #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid .response {
        border: solid 2px #7fbc4d;
        background-color: #7fbc4d; }
        #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid .response .title {
          color: #18202f; }
        #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.valid .response:after {
          background: url(/static/media/ic-good-answer.f10abf3b.svg) no-repeat; }
      #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected.valid .response {
        border: solid 2px #7fbc4d;
        background-color: white; }
        #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected.valid .response .title {
          color: #7fbc4d; }
      #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.invalid .response {
        border: solid 2px #ee3547;
        background-color: white; }
        #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.invalid .response:after {
          background: url(/static/media/ic-bad-answer.3d908085.svg) no-repeat; }
      #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected.invalid .response {
        border: solid 2px #ee3547;
        background-color: white; }
        #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container.selected.invalid .response .title {
          color: #18202f; }
      #quiz-categorisation .digischool-quiz-player .quiz-player .subquestion-container .subquestion .answers-container .answer-container span {
        display: none; }

